/* Reset and Typography */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  line-height: 1.4;
  font-size: 14px;
  color: #555;
  background-color: #f9f9f9;
}

h1 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 1rem;
}

h3 {
  margin-bottom: 1rem;
}

  
/* Containers */
.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px;
  margin: 0 auto;
}

.wrapper {
  padding: 1rem;
}

.admin-page {
  display: flex;
  flex-direction: row;
  height: 100vh;
  align-items: flex-start;
}

.main-container {
  flex-grow: 1;
  flex-basis: 90%;
  padding: 1rem;
  max-width: 1200px; /* Set the maximum width of the main-container */
}


  
  /* Form Elements */

  form label {
    display: inline-block;
    margin-right: 1rem;
    margin-bottom: 0.5rem;
  }
  
  form label input {
    margin-left: 0.5rem;
  }

  input,
  select,
  textarea {
    width: 100%;
    padding: 0.4rem;
    margin-bottom: 1rem;
    font-size: 0.9rem;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  input[type="text"] {
    width: 50%;
  }

  input[type="number"] {
    width: 10%;
  }
  
  button {
    width: 150px;
    padding: 0.4rem;
    border: none;
    border-radius: 5px;
    background-color: #333;
    font-size: 0.9rem;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #555;
  }
  
  .btn-block {
    margin-top: 1rem;
    text-align: center;
  }
  
  /* Lists */
  ul {
    list-style: none;
    padding-left: 1rem;
  }
  
  /* Tables */
  .table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 1rem;
  }
  
  .table th,
  .table td {
    border: 1px solid #ddd;
    padding: 0.3rem;
    text-align: left;
    align-items: center;
  }
  
  .table th {
    background-color: #f9f9f9;
    color: #333;
  }

/* Table columns in order to fix them */
.col,
.col-packageId,
.col-unit,
.col-courier,
.col-packagetype,
.col-tracking,
.col-location,
.col-conciergeId,
.col-deliveryDate,
.col-pickedupAt,
.col-pickedupResidentId,
.col-pickedupConciergeId {
    min-width: 10%; /* Adjust this value according to your desired column width */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.cell-tracking {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
  
  /* Checkboxes */
  .checkbox {
    transform: scale(1.5);
  }
  
  /* Highlights */
  .highlighted {
    background-color: rgba(0, 128, 0, 0.4); /* Increased intensity */
    transition: background-color 2s ease-out;
  }

  /* Banner Image */
  .banner-container {
    padding: 1px;
  }

  .banner-image {
    margin-top: 1rem;
    margin-bottom: 1rem;
    padding: 1rem; /* Add padding to all sides */
    box-sizing: border-box; /* Make sure the padding is included in the element's dimensions */
    width: 100%; /* Set the width to 100% so it stretches across the entire container */
    object-fit: cover; /* Ensure the image covers the entire area without distortion */
  }


  /* Resident Components */  
  .resident-component-tile {
    background-color: #ffffff; /* Tile background color */
    border: 1px solid #e0e0e0; /* Border color and width */
    border-radius: 8px; /* Rounded corners */
    padding: 20px; /* Inner padding */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Box shadow for depth */
  }  

  /* Admin Components */  
  .admin-component-tile {
    background-color: #ffffff; /* Tile background color */
    border: 1px solid #e0e0e0; /* Border color and width */
    border-radius: 8px; /* Rounded corners */
    padding: 20px; /* Inner padding */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Box shadow for depth */
  }

  /* Adds a margin to the top of the element (for TodayDelivery)*/
  .margin-top {
    margin-top: 1rem; /* Adjust the value as needed */
  }

  /* Sidebar */

  .sidebar {
    width: 10%;
    min-width: 250px;
    padding: 1rem;
  }
  
  .sidebar-container {
    background-color: #ffffff; /* Tile background color */
    border: 1px solid #e0e0e0; /* Border color and width */
    border-radius: 8px; /* Rounded corners */
    padding: 20px; /* Inner padding */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Box shadow for depth */
  }

  .sidebar-entry {
    display: block;
    padding: 0.5rem 1rem;
    text-decoration: none;
    color: #555;
    border-radius: 4px;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  }

  .sidebar-entry:hover {
    background-color: #333;
    color: #fff;
  }
    

  /* Sort Icons for Tables */

.sort-icon {
  font-size: 12px;
  margin-left: 5px;
  vertical-align: middle;
}

.sort-inactive::before {
  content: '\2195'; /* Double-headed arrow */
  opacity: 0.5;
}

.sort-asc::before {
  content: '\2191'; /* Up arrow */
}

.sort-desc::before {
  content: '\2193'; /* Down arrow */
}

/* Form Grid */
.form-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 1rem;
  margin-bottom: 1rem;
}

.form-group {
  display: flex;
  flex-direction: column;
}

/* DeliveryForm Success Message */

.success-message {
  position: fixed;
  right: 20px;
  bottom: 20px;
  background-color: rgba(0, 128, 0, 0.8);
  color: white;
  font-size: 18px; /* Increased font size */
  font-weight: bold;
  padding: 12px 24px; /* Increased padding */
  border-radius: 10px; /* Increased border radius */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}


.success-message-hidden {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.success-message-visible {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}



