/* modal-popup.css */
.modal-popup {
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.4);
  }
  
  .modal-popup-content {
    background-color: #ffffff;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 50%;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  
  .modal-popup-buttons {
    display: flex;
    justify-content: center; /* center the buttons horizontally */
    margin-top: 32px; /* increase the top margin */
    gap: 16px; /* add some spacing between the buttons */
    flex-wrap: wrap; /* wrap the buttons to a new line if they don't fit */
}